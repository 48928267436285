* {
  margin: 0;
}
/* sidebar*/
body > div#root > div > div:first-child {
  background-color: #ffffff;
  box-shadow: 0px 5px 20px rgba(69, 72, 84, 0.04);
  border-color: rgba(121, 122, 124, 0);
}

body > div#root > div > div > div > button {
  display: none;
  text-decoration: none;
  cursor: default;
  pointer-events: none;
}
/*todos os botões*/
body > div#root > div > div > ul.MuiList-root a div.MuiButtonBase-root {
  margin: 25px 0 0 0;
  background-color: #ffffff;
}

body > div#root > div > div > ul.MuiList-root a div.MuiButtonBase-root div.MuiListItemIcon-root svg {
  background-color: #ffffff;
}

body > div#root > div > div > ul.MuiList-root a.active-link div.MuiButtonBase-root {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/*container de botoes do sidebar*/
body > div#root > div > div > ul.MuiList-root {
  flex: 4;
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*primeiro botao = logo*/
body div#root div div ul.MuiList-root a:first-child div:first-child div.MuiListItemIcon-root:first-child,
body div#root div div ul.MuiList-root a:first-child div.MuiButtonBase-root:first-child,
body > div#root > div > div > ul.MuiList-root > a:first-child {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  width: auto;
  position: fixed;
  padding-left: 1px;
  top: 0;
  margin-top: 10px;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.react-pdf__Page__textContent {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 5px;
}

.MuiAutocomplete-endAdornment {
  margin-top: -6px;
}

.imgAcesso {
  height: 100%;
  width: 100%;
}
